import * as React from "react";
import * as z from "zod";
import axios from "axios";
import { useQuery } from "react-query";
import { TextField, Autocomplete, LinearProgress, Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const FormateurValidator = z.array(z.object({ id: z.string().min(3).max(10), firstname: z.string().nullable(), lastname: z.string() }));
const FormateurData = () => {
    const params = useParams();
    return useQuery(
        ["get-formateur"],
        async () => {
            const res = await axios.get(`/outil_formation/api/formateurs/${params.societe}`).then((response) => response.data.instructors);
            return FormateurValidator.parse(res);
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};
export default function Formateurs() {
    const { isLoading, isError, data } = FormateurData();
    let navigate = useNavigate();
    const params = useParams();
    if (isLoading) return <LinearProgress />;
    if (isError) return <div>Error</div>;
    if (data)
        return (
            <>
                <Typography display="flex" justifyContent="center" alignItems="center">Dtalents {params.societe}</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" marginTop={'2%'}>
                    <Autocomplete
                        onChange={(e, value) => {
                            if (value !== null) {
                                navigate(`/outil_formation/formateurs/${value.id}/${params.societe}`);
                            }
                        }}
                        disablePortal
                        id="box-formateurs"
                        options={data}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) => option.lastname + " " + option.firstname + " - " + option.id}
                        renderInput={(params) => <TextField {...params} label="Formateurs" />}
                    />
                </Box>
            </>
        );
}
