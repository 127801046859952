import * as React from "react";
import * as z from "zod";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import axios from "axios";

const FormateurValidator = z.array(z.object({
    id: z.string().min(3).max(10),
    firstname: z.string().nullable(),
    lastname: z.string()
}));
const FormateurData = () => {
    const params = useParams();
    return useQuery(
        ["get-formateur"],
        async () => {
            const res = await axios.get(`/outil_formation/api/formateurs/consulting`).then((response) => response.data.instructors);
            return FormateurValidator.parse(res);
        },
        {
            onError: (err) => {
                console.error("Fetch API error", err);
            },
        }
    );
};
export default function Dbug() {
    const {isLoading, isError, data} = FormateurData();
    console.log(isError, data);
    return <>zzz</>;
}
